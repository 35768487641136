import { Box, HStack, Text, VStack } from '@chakra-ui/react'

import { LetterQRCode } from '~features/editor/components/LetterQRCode'
import { WIDTH_A4 } from '~utils/htmlUtils'

import { LetterInfoCard } from './LetterInfoCard'

export interface LetterFooterCardProps {
  letterPublicId: string
  transformScale: number
  createdAt: Date
  displayResponsive?: boolean | undefined
}

export const LetterFooterCard = ({
  letterPublicId,
  transformScale,
  createdAt,
  displayResponsive = false,
}: LetterFooterCardProps): JSX.Element => {
  return (
    <VStack
      transform={displayResponsive ? undefined : `scale(${transformScale})`}
      transformOrigin={displayResponsive ? undefined : 'top'}
      align={'center'}
      width={displayResponsive ? '90vw' : WIDTH_A4}
      maxW={WIDTH_A4}
      spacing="0"
      paddingBottom={'12px'}
    >
      <Box bgColor="grey.50" width="100%">
        <Box paddingBottom={'10px'} paddingX="28px" paddingY="0.75rem">
          <HStack spacing="0.25rem" alignItems="top">
            <Box
              w={
                displayResponsive
                  ? { base: '25%', sm: '15%', md: '10%' }
                  : '10%'
              }
            >
              <LetterQRCode
                qrCodeLinkValue={`https://${document.location.host}/letters/${letterPublicId}`}
              />
            </Box>
            <VStack
              spacing="0.25rem"
              alignItems="flex-start"
              style={{ margin: 'auto 10px' }}
            >
              <Text
                textColor="grey.700"
                fontSize="sm"
                // -5px to align the top of the QR Code with the 'Letter ID:...' text
                style={{ margin: '-5px 0 0 0' }}
              >
                Letter ID: <b>{letterPublicId}</b>
              </Text>

              <Text textColor="grey.700" textStyle="legal">
                Issued On: {new Date(createdAt).toString()}
              </Text>
              <Text textStyle="legal" textColor="grey.700">
                This letter is issued via LetterSG, a platform for trusted
                e-letters from the Singapore Government and public sector
                agencies.
              </Text>
            </VStack>
          </HStack>
        </Box>
        <LetterInfoCard />
      </Box>
    </VStack>
  )
}
