import {
  Alert,
  AlertIcon,
  Box,
  HStack,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'

import { useAdminAuth } from '~features/auth/context/AdminProtectedContext'
import { LetterViewer } from '~features/editor/components/LetterViewer'
import { onHtmlImagesLoaded, WIDTH_A4 } from '~utils/htmlUtils'

import { CitizenMenu } from './components/CitizenMenu'
import { LetterFooterCard } from './components/LetterFooterCard'
import {
  useGetLetterByPublicId,
  useLetterPublicId,
} from './hooks/letters.hooks'
import { useTransformScale } from './hooks/useTransformScale'
import { NotFoundComponent } from './NotFoundComponent'

export const LetterPublicPage = (): JSX.Element => {
  const { letterPublicId } = useLetterPublicId()
  const { adminUser } = useAdminAuth()
  const transformScale = useTransformScale()
  const [letterHeight, setLetterHeight] = useState<number | undefined>(0)
  const letterViewerRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        // explicitly waits for images to load before setting letter height
        onHtmlImagesLoaded(node, () => setLetterHeight(node.offsetHeight))
      }
    },
    [setLetterHeight],
  )

  const { letter, isLetterLoading, error } = useGetLetterByPublicId({
    letterPublicId,
  })

  if (error) {
    return <NotFoundComponent />
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <div>
        <>
          <Box w="full" h="100%" bg="grey.100">
            <VStack spacing="0" pb={5}>
              {!!adminUser && (
                <Alert
                  status="info"
                  w={WIDTH_A4}
                  maxW={'90vw'}
                  my={3}
                  backgroundColor={'brand.primary.100'}
                  borderColor={'base.content.brand'}
                  borderWidth={'1px'}
                  borderRadius={'4px'}
                  mb="0"
                >
                  <HStack align={'top'} spacing="0">
                    <AlertIcon />
                    <Text textStyle={'body-2'} color={'brand.secondary.700'}>
                      You are currently logged in as an agency user. The read
                      status will not be updated. To test for a status update,
                      please open the letter in a separate device or log out of{' '}
                      <Link
                        href="https://letters.gov.sg"
                        color={'brand.secondary.700'}
                      >
                        letters.gov.sg
                      </Link>{' '}
                      beforehand.
                    </Text>
                  </HStack>
                </Alert>
              )}
              <CitizenMenu letter={letter} />
              <VStack
                align={'center'}
                height={
                  letter?.displayResponsive || !letterHeight
                    ? 'auto'
                    : letterHeight * transformScale
                }
                mt={'20px'}
                w={WIDTH_A4}
                maxW={'90vw'}
              >
                <LetterViewer
                  ref={letterViewerRef}
                  html={letter?.issuedLetter}
                  displayResponsive={letter?.displayResponsive}
                  isLoading={isLetterLoading}
                  transformScale={transformScale}
                />
              </VStack>
              {letterPublicId && letter && (
                <LetterFooterCard
                  letterPublicId={letterPublicId}
                  displayResponsive={letter?.displayResponsive}
                  transformScale={transformScale}
                  createdAt={letter.createdAt}
                />
              )}
            </VStack>
          </Box>
        </>
      </div>
    </>
  )
}
